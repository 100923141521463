
import { defineComponent } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta';

// defineComponent allows the user of either composition API or options API
// Below is an example of using the options API
export default defineComponent({
    name: 'HomePage',
    head(): MetaInfo {
        return {
            title: 'Articles HomePage',
        };
    },
});
